import crebitoIntl from '@apegro/crebito-intl';

import i18n from './i18n';

const params = {
  lib: i18n,
  fName: 't',
};

const t = crebitoIntl.translations(params);
export default t;
